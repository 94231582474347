import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DropDown } from '@/components/dropdown';
// import heroImage from '/img/hero-new/hero.png';

/* eslint-disable */
export const Hero = () => {
  const navigate = useNavigate();
  const [categorySelection, setCategorySelection] = React.useState('none');
  const [categoryCollapse, setCategoryCollapse] = React.useState(false);
  const [modeSelection, setModeSelection] = React.useState('none');
  const [modeCollapse, setModeCollapse] = React.useState(false);
  // const onChangeCategory = (change: any) => {
  //   setCategorySelection(change);
  // };
  // const onChangeMode = (change: any) => {
  //   setModeSelection(change);
  // };

  const categoryData: { [key: string]: { label: string; description: string } } = {
    digital: {
      label: 'Habilidades Digitales',
      description:
        'Aprende a incorporar el uso de tecnologías para aplicarlas en este mundo digital.',
    },
    personal: {
      label: 'Habilidades Personales',
      description:
        'Desarrolla habilidades humanas para el trabajo en equipo o crecimiento personal.',
    },
    gestion: {
      label: 'Habilidades de Gestión',
      description:
        'Conoce sobre la coordinación en equipos para llevar a cabo o para conseguir algo y resolver problemáticas.',
    },
    none: {
      label: '¿Qué te gustaría aprender?',
      description: '',
    },
  };
  const modeData: { [key: string]: { label: string; description: string } } = {
    online: {
      label: 'Online en vivo',
      description:
        'Con la comodidad de aprender desde donde quieras manteniendo la cercanía con tu entrenador y compañeros.',
    },
    ondemand: {
      label: 'On Demand',
      description:
        'Con la libertad de aprender sin horarios, sin límites, a tu ritmo y desde donde quieras.',
    },
    presencial: {
      label: 'Presencial',
      description: '',
    },
    none: {
      label: '¿De qué manera te gustaría aprender?',
      description: '',
    },
  };

  const navigateToCategory = () => {
    navigate(`/programas/categorias/${categorySelection}/${modeSelection}`, { replace: true });
  };

  return (
    <div>
      <section className="hero home_hero pink_bg d-flex">
        <div className="container">
          <div className="row d-flex align-items-center h-100">
            <div className="col-lg-7 pb-5 position-relative d-flex flex-column">
              <article>
                <h1>Somos la plataforma de <b>aprendizaje</b> para tu <b>evolución continua</b></h1>
                <p>
                  Diplomaturas universitarias diseñadas por los mejores expertos, para que potencies
                  tus habilidades al máximo.
                </p>
                <form className="white_bg rounded p-3 shadow mt-3">
                  <div className="row justify-content-between align-items-center">
                    <div className="col">
                      <DropDown
                        handleChange={setModeSelection}
                        onToggleCollapse={() => {
                          if (!modeCollapse) setCategoryCollapse(false);
                        }}
                        isCollapsed={modeCollapse}
                        setCollapsed={setModeCollapse}
                        title={'Modalidad'}
                        options={modeData}
                        showTitle
                        key={'mode-drop-down'}
                      />
                    </div>

                    <div className="col px-0">
                      <DropDown
                        handleChange={setCategorySelection}
                        onToggleCollapse={() => {
                          if (!categoryCollapse) setModeCollapse(false);
                        }}
                        isCollapsed={categoryCollapse}
                        setCollapsed={setCategoryCollapse}
                        title={'Categoria'}
                        options={categoryData}
                        showTitle
                        key={'category-drop-down'}
                      />
                    </div>
                    <div className="col">
                      <a
                        className="btn primary-btn btn-rounded red_bg white d-block"
                        onClick={() => navigateToCategory()}>
                        Buscar
                      </a>
                    </div>
                  </div>
                </form>
              </article>
            </div>
            <div className="col-lg-5 d-flex flex-column align-items-end  h-100 justify-content-end">
              <div
                style={{ backgroundImage: 'url("img/home-new/hero2.png")' }}
                className="img-fluid hero-image"></div>
              {/* <div id="hero_carousel" className="carousel slide" data-bs-ride="carousel"> */}
              {/* <div className="carousel-indicators">
                                <button type="button" data-bs-target="#hero_carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#hero_carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            </div> */}
              {/* <div className="carousel-inner">
                  <div className="carousel-item active">
                   
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
