import React from 'react';
import { Link } from 'react-router-dom';
export const Skills = () => {
  return (
    <section className="habilidades container py-100">
      <div className="row">
        <div className="col-lg-7">
          <article className={' mb-4'}>
            <h2 className="mb-4">
              Sumate al mundo digital y aprende las habilidades más demandadas
            </h2>
            <p className="lead mt-3">
              Enfrentamos un mundo ágil que nos invita a anticiparnos al cambio, a la necesidad de
              aprender constantemente y dominar las herramientas propias de cada especialidad. Las
              habilidades de gestión, personales o humanas en conjunto con las nuevas herramientas
              digitales, te preparan para alcanzar el éxito y te ayudan a gestionar tu bienestar y
              el de tu entorno.
            </p>
          </article>
          <article className={'mt-3'}>
            <div className={'row'}>
              <div className="col-12 d-flex">
                <h3 className={'mb-4'}> Te contamos cuales son</h3>
              </div>
            </div>

            <div className="row d-flex justify-content-betwenn align-items-center">
              <div className="col-lg-4 d-flex justify-content-center align-items-center">
                <Link to={'/programas/categorias/gestion'} className="btn btn-secondary btn-small">
                  Habilidades de gestión
                </Link>
                {/* <a
                    className="btn btn-outline btn-rounded dark-grey_border"
                    onClick={() => ploc.linkCourseSearch('none', 'gestion')}>
                    <span>Habilidades</span> de gestión
                  </a> */}
              </div>
              <div className="col-lg-4 d-flex justify-content-center align-items-center">
                <Link to={'/programas/categorias/personal'} className="btn btn-secondary btn-small">
                  Habilidades personales
                </Link>
                {/* <a
                    className="btn btn-outline btn-rounded dark-grey_border"
                    onClick={() => ploc.linkCourseSearch('none', 'personal')}>
                    <span>Habilidades</span> humanas
                  </a> */}
              </div>
              <div className="col-lg-4 d-flex justify-content-center align-items-center">
                <Link to={'/programas/categorias/digital'} className="btn btn-secondary btn-small">
                  Habilidades digitales
                </Link>
                {/* <a
                    className="btn btn-outline btn-rounded dark-grey_border"
                    onClick={() => ploc.linkCourseSearch('none', 'digital')}>
                    <span>Habilidades</span> digitales
                  </a> */}
              </div>
            </div>
          </article>
        </div>
        <div className="col-lg-4 offset-lg-1">
          <div
            id="habilidades_carousel"
            className="carousel slide carousel-fade habilidades_carousel"
            data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active p-1">
                <article className="shadow-1">
                  <figure className="modalidad modalidad-online position-relative">
                    <img
                      src={'/img/data_science_temp.jpg'}
                      alt={''}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <figcaption className="p-3">
                      <h3 className={'my-3'}>Data Science</h3>
                      {/* <span className="badge rounded-pill online">Modalidad Online</span>
                                            <p>Trabajar estratégicamente con grandes cantidades de datos permite tomar las mejores decisiones en nuestros entornos.</p>
                                            <div className="schedule d-flex justify-content-between">
                                                <div className="encuentros d-flex">
                                                    <p><em className="icon"></em> 29 Encuentros</p>
                                                </div>
                                                <div className="inicio d-flex">
                                                    <p><em className="icon"></em> Inicia 20/03/22</p>
                                                </div>
                                            </div> */}
                      <p className="text-center">
                        <Link to={'/programas/34'} className="btn btn-secondary">
                          Más información
                        </Link>
                      </p>
                    </figcaption>
                  </figure>
                </article>
              </div>
            </div>
            {/* <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#habilidades_carousel"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"></button>
              <button
                type="button"
                data-bs-target="#habilidades_carousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
