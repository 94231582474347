import React from 'react';

export const SocialMedias = () => {
  return (
    <div className="row d-flex justify-content-between contact_socialMedia">
      <figure className="col-sm ">
        <a href="/ " title="Volver al Inicio ">
          <img src="/img/logo.png " alt="Mundos E " width="150 " />
        </a>
      </figure>
      <nav className="social_foot_nav col-sm ">
        <ul className=" d-flex justify-content-end list-unstyled ">
          <li>
            <a
              href="https://www.facebook.com/mundose"
              target="_blank "
              title="Seguinos en Facebook "
              className="grey_bg white ">
              <i className="fab fa-facebook-f "></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/mundoseoficial"
              target="_blank "
              title="Seguinos en Instagram "
              className="grey_bg white ">
              <i className="fab fa-instagram "></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/school/fundacion-mundos-e/"
              target="_blank "
              title="Seguinos en Linkedin "
              className="grey_bg white ">
              <i className="fab fa-linkedin-in "></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCIt4Ecn2zTioTJwg1-bxHkA"
              target="_blank "
              title="Seguinos en Youtube "
              className="grey_bg white ">
              <i className="fab fa-youtube "></i>
            </a>
          </li>
          <li>
            <a
              href="https://wa.link/yqukyz"
              target="_blank "
              title="Encontranos en WhatsApp "
              className="red_bg white ">
              <i className="fab fa-whatsapp "></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
