import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { env, getToken } from '@/utils';
import { logout } from '@/store/slices/user';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useWindowDimensions } from '@/hooks';

export const NavBar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  /* eslint-disable */
  const { user } = useAppSelector(state => state.user);

  /* eslint-enable */
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loggedOptions = [
    { path: '/usuario/perfil', title: 'MI PERFIL' },
    { path: '/usuario/programas', title: 'MIS PROGRAMAS' },
    { path: '/usuario/certificados', title: 'MIS CERTIFICADOS' },
    { path: '/usuario/configuracion', title: 'CONFIGURACIÓN' },
  ];

  const logged = useMemo(
    () => user.mail != null && user.mail != '' && getToken() && getToken() != '',
    [user]
  );

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemCollapse = () => setIsNavCollapsed(false);

  const wide = useWindowDimensions(700);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top d-flex align-items-center">
      <div className="container">
        <div className="d-flex">
          <Link to={'/'} className="navbar-brand mr-4">
            MundosE
          </Link>
          <Link
            to={'/programas'}
            className="btn primary-btn btn-rounded red_bg white d-none d-sm-block btn-small">
            Programas{' '}
            <em>
              <img src={'/img/programas_icon.svg'} height="16px" alt={'programas'} />
            </em>
          </Link>
        </div>

        <div>
          <button
            className="navbar-toggler red_bg white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}>
            <span className="navbar-toggler-icon"> </span>
          </button>

          <div
            className={`${isNavCollapsed ? 'show' : ''} collapse navbar-collapse ml-auto`}
            id="navbarNavDropdown">
            <div className="navbar-nav">
              <Link
                to={'/programas'}
                onClick={handleNavItemCollapse}
                className="nav-link btn-tertiary d-sm-none">
                Programas
              </Link>
              <a
                className="nav-link btn-tertiary"
                target="_blank"
                href="https://blog.mundose.com"
                rel="noreferrer"
                onClick={handleNavItemCollapse}>
                Blog
              </a>
              <Link
                to={'/consultas'}
                onClick={handleNavItemCollapse}
                className="nav-link btn-tertiary">
                Ayuda
              </Link>
              {!logged && (
                <>
                  <Link
                    to={'/registro'}
                    onClick={handleNavItemCollapse}
                    state={{ backgroundLocation: location }}
                    className="btn primary-btn btn-rounded red_bg white d-none d-sm-block btn-small ms-3">
                    Registro
                  </Link>
                  <Link
                    to={'/login'}
                    onClick={handleNavItemCollapse}
                    state={{ backgroundLocation: location }}
                    className="btn primary-btn btn-rounded red_bg white d-none d-sm-block btn-small ms-3">
                    Iniciar sesión
                  </Link>
                  {/* Versión de móvil */}
                  <Link
                    to={'/registro'}
                    onClick={handleNavItemCollapse}
                    state={{ backgroundLocation: location }}
                    className="nav-link btn-tertiary d-sm-none">
                    Registro
                  </Link>
                  <Link
                    to={'/login'}
                    onClick={handleNavItemCollapse}
                    state={{ backgroundLocation: location }}
                    className="nav-link btn-tertiary d-sm-none">
                    Iniciar sesión
                  </Link>
                </>
              )}
              {logged && (
                <>
                  {loggedOptions.map((l, i) => (
                    <Link
                      to={l.path}
                      key={i}
                      onClick={handleNavItemCollapse}
                      className="nav-link btn-tertiary d-sm-none">
                      {l.title}
                    </Link>
                  ))}
                  <Link
                    to={'/'}
                    onClick={() => {
                      dispatch(logout());
                      handleNavItemCollapse();
                    }}
                    className="nav-link btn-tertiary d-sm-none">
                    Cerrar Sesión
                  </Link>
                  <div className="dropdown d-none d-sm-flex ms-2 user-menu-wrapper">
                    <button
                      className="dropdown-toggle btn p-0"
                      type="button"
                      id="avatar-togler"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {user && user.image_profile != null ? (
                        <img
                          src={user.image_profile}
                          className="img-fluid avatar"
                          alt="Nombre de usuario "
                          style={{ objectFit: 'cover' }}
                        />
                      ) : (
                        <figure className="avatar ms-2">
                          <img
                            src={'/img/mi_perfil.svg'}
                            className="img-fluid "
                            alt="Nombre de usuario "
                          />
                        </figure>
                      )}
                    </button>
                    <ul
                      className="dropdown-menu shadow-2 dropdown-menu-right"
                      aria-labelledby="avatar-togler">
                      {loggedOptions.map((l, i) => (
                        <li key={`li-key-${i}`}>
                          <Link to={l.path} key={i} className="dark-grey">
                            {l.title}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link
                          to={'/'}
                          onClick={() => {
                            dispatch(logout());
                          }}
                          className="dark-grey mb-0">
                          Cerrar Sesión
                        </Link>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* {logged && (
            <div className="collapse navbar-collapse ml-auto" id="navbarNavDropdown">
              <div className="navbar-nav">
                <Link to={'/programas'} className="nav-link dark-grey d-block d-sm-none">
                  Programas
                </Link>
                <a
                  className="nav-link btn-tertiary"
                  target="_blank"
                  href="http://www.eldodo.com.ar/mundos-blog/"
                  rel="noreferrer">
                  Blog
                </a>

                <Link to={'/consultas'} className="nav-link btn-tertiary">
                  Ayuda
                </Link>
              </div>

              <div className="notifications-wrapper d-none">
                <figure className="notifications ms-2">
                  <img
                    src={'/img/notificaciones.svg'}
                    className="img-fluid "
                    alt="Nombre de usuario "
                  />
                </figure>
              </div>

              <div
                className="user-menu-wrapper navbar-toggler d-none"
                data-bs-toggle="collapse"
                data-bs-target="#avatar-togler"
                aria-controls="avatar-togler"
                aria-expanded="false"
                aria-label="avatar-togler ">
                <figure className="avatar ms-2">
                  <img src={'/img/mi_perfil.svg'} className="img-fluid " alt="Nombre de usuario " />
                </figure>
                <div className="collapse ml-auto" id="avatar-togler">
                  <div className="navbar-nav shadow-2">
                    {loggedOptions.map((l, i) => (
                      <Link to={l.path} key={i} className="dark-grey">
                        {l.title}
                      </Link>
                    ))}
                    <Link
                      to={'/'}
                      onClick={() => {
                        dispatch(logout());
                      }}
                      className="dark-grey mb-0">
                      Cerrar Sesión
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </nav>
  );
};
