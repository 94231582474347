import React from 'react';

export const WeTransformLearning = () => {
  return (
    <section className="container py-100 transformamos-aprendizaje">
      <header className="row">
        <div className="col-lg-8">
          <h2>Transformamos el aprendizaje en experiencias de vida</h2>
          <div>
            <h3 className="my-4">Te contamos como</h3>
            <p className="lead">
              La trasversalidad de nuestros programas te permiten vivir experiencias reales
              enfocadas en las necesidades de hoy para fortalecer tu confianza y ayudarte a crecer.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <figure>
            <img
              src="/img/home-new/experiencias-de-vida2.png"
              className="img-fluid"
              alt="Transformamos el aprendizaje en experiencias de vida"
            />{' '}
          </figure>
        </div>
      </header>
    </section>
  );
};
